export const handleCarClick = (navigate, id, title, category, brand, model, city, distt, km) => {

  const cleanedDescription = encodeURIComponent(title?.replace(/\s+/g, '-').toLowerCase());
  const cleanedCategory = encodeURIComponent(category?.replace(/\s+/g, '-').toLowerCase());
  const cleanedBrand = encodeURIComponent(brand?.replace(/\s+/g, '-').toLowerCase());
  const cleanedModel = encodeURIComponent(model?.replace(/\s+/g, '-').toLowerCase());
  const cleanedDistt = encodeURIComponent(distt?.replace(/\s+/g, '-').toLowerCase());
  const cleanedCity = encodeURIComponent(city?.replace(/\s+/g, '-').toLowerCase());
  

    navigate(`/vehicle-details/${cleanedCategory}-${cleanedBrand}-${cleanedModel}-${cleanedDescription}-${km || 0}-${cleanedCity}-${cleanedDistt}?id=${id}`);
  };


export  const handleRealEstateClick = (navigate, id, title, category, brand, city, district) => {
    const cleanedDescription = title?.replace(/\s+/g, '-').toLowerCase()
    const cleanedCategory = category?.replace(/\s+/g, '-').toLowerCase()
    const cleanedBrand = brand?.replace(/\s+/g, '-').toLowerCase()
    const cleanedCity = city?.replace(/\s+/g, '-').toLowerCase()
    const cleanedDistrict = district?.replace(/\s+/g, '-').toLowerCase()

    navigate(`/real-estate-details/${cleanedCategory}-${cleanedBrand}-${cleanedDescription}-${cleanedCity}-${cleanedDistrict}?id=${id}`);
  };