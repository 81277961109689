import { useState, useEffect, useRef } from "react";
import axios from "axios";

import year from "../../images/yeear.svg";
import searchIcon from "../../images/searchIcon.svg";
import engineSize from "../../images/engine.svg";
import calendar from "../../images/calendar.svg";
import Cookies from "js-cookie";
import Group11921 from "../../images/Group 11921.svg";
import Group11922 from "../../images/Group 11922.svg";
import Group11923 from "../../images/Group 11923.svg";
import Group11924 from "../../images/Group 11924.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { baseURL1 } from "../../utils/baseUrl";
import apiClient from "../../utils/apiClient";
import { useSelector } from "react-redux";
import strings from "../../utils/locals/languages";
import Footer from "../../components/Footer";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { handleCarClick } from "../../services/handlers";


const Body = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("");
  const [selectedMId, setSelectedMId] = useState("");
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [models1, setModels1] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [purpose, setPurpose] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [reLoad, setReLoad] = useState(0);
  const [userId, setUserId] = useState("");
  const [partners, setPartners] = useState([])
  const [ads, setAds] = useState([])
  const user = Cookies.get("userDetails");
  const [totalAds, setTotalAds] = useState(0)


  const addToFavorites = (event, adId) => {
    event.stopPropagation();

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        apiClient
          .get(
            `${baseURL1}/v1/adds/search/?section=638f1fd34cb7c29ec6ad4bd6&&purpose=${purpose}&category=${selectedId}&language=${strings._language}&subcat=${selectedMId}
        `
          )
          .then((response) => {
            setVehicleData(response.data.data);
            setIsLoading(false);
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  const language = useSelector((state) => state.language);

  const searchFilter = () => {
    setIsLoading(true);
    apiClient
      .get(
        `${baseURL1}/v1/adds/search/?section=638f1fd34cb7c29ec6ad4bd6&&purpose=${purpose}&category=${selectedId}&language=${strings._language}&subcat=${selectedMId}
        `
      )
      .then((response) => {
        setVehicleData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });
  };

  const getAds = async () => {
    try {
      const response = await apiClient('/v1/user/get-banners/638f1fd34cb7c29ec6ad4bd6')
      setAds(response.data.banners)
    } catch {
      setAds([])
    }
  }

  useEffect(() => {
    searchFilter();
  }, [strings._language, reLoad, purpose, selectedId, selectedMId]);


  useEffect(() => {
    searchFilter();
  }, [strings._language, reLoad, purpose, selectedId, selectedMId]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setBrands(response.data[0].categories);

        let total = 0;
        response.data[0].categories.forEach(element => {
          total += element.totalAds;
        });
        setTotalAds(total)
        setBrandsLoading(false);
      })
      .catch((err) => { });
  }, [strings._language]);

  useEffect(() => {
    if (selectedId) {
      axios
        .get(
          `${baseURL1}/v1/adds/brands-dropdown?catoryId=${selectedId}&language=${strings._language}`
        )
        .then((response) => {
          setModels(response.data);
        })
        .catch((err) => { });
    }
  }, [selectedId]);



  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await apiClient.get('/v1/user/view-partners?location=vehicle');
      setPartners(response.data.partners);
    } catch (err) {
      console.error('Error fetching partners:', err);
    }
  };

  useEffect(() => {
    fetchPartners()

    getAds()
  }, [])

  const itemsToShow = 8

  const itemWidth = 300
  const isEndReached = (itemWidth * (brands.length - (itemsToShow)))
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollContainerRef = useRef(null);


  const handleWheelScroll = (event) => {
    event.stopPropagation()

    event.preventDefault();

    // Scroll the div horizontally
    const container = scrollContainerRef.current;
    container.scrollLeft += event.deltaY;
    if (scrollPosition >= 0) {
      setScrollPosition(container.scrollLeft += event.deltaY)
    }
  };


  const nextButtonClickHandler = () => {
    const nextPosition = Math.min(
      scrollPosition + itemWidth,
      ((brands.length) - itemsToShow) * itemWidth
    );

    if (isEndReached >= scrollPosition) {
      setScrollPosition(nextPosition);
    }
  };

  const prevButtonClickHandler = () => {
    const prevPosition = Math.max(scrollPosition - itemWidth, 0);
    setScrollPosition(prevPosition);
  };


  const vehicleItemsToShow = 5

  const [vehicleScrollPosition, setVehicleScrollPosition] = useState(0);

  const isVehicleEndReached = (vehicleData.length * 250)
  const isVehicleStartReached = scrollPosition === 0;

  const vehicleNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      vehicleScrollPosition + itemWidth,
      ((vehicleData.length + 2) - vehicleItemsToShow) * itemWidth
    );

    if (isVehicleEndReached >= vehicleScrollPosition) {
      setVehicleScrollPosition(nextPosition);
    }
  };

  const vehiclePrevButtonClickHandler = () => {
    const prevPosition = Math.max(vehicleScrollPosition - itemWidth, 0);
    setVehicleScrollPosition(prevPosition);
  };

  return (
    <div
    >
      <div className="background-image">
        <div className="container">
          <div className="row  flex justify-center mb-3">
            <div className="col-12 col-md-12 col-lg-6">

              <h3
                className="text-center  text-white"
                style={{ fontSize: "40px" }}
              >
                {strings.rightVehicle}
              </h3>

            </div>
          </div>

          <div className="row  flex justify-center mb-3">
            <div className="col-12 col-md-12 col-lg-6  items-center flex justify-center p-2 rounded-2xl">
              <div className="bg-white shadow-md items-center flex justify-center p-2 w-[85%] rounded-full">
                <input type="text" className="outline-none bg-white w-[90%]"
                  placeholder={capitalizeFirstLetter(strings.searchSomething)}
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                <img src={searchIcon} className="h-[24px] w-[24px]" />
              </div>
            </div>
          </div>

        </div>
      </div>

      {/*......................*/}

      <div className="container mt-8 relative ">
        <div
          className="absolute z-50 top-32 -left-12 "
        >
          <ArrowBackIosNewIcon
            className={`cursor-pointer   rounded-full p-1 bg-white text-[#F37521] `}
            onClick={() => prevButtonClickHandler()}
            style={{
              height: 48,
              width: 48
            }}
          />
        </div>

        <div
          className="absolute z-50 top-32 -right-14 "
        >
          <ArrowForwardIosIcon
            className={`cursor-pointer   rounded-full text-xl p-1 bg-white text-[#F37521] `}
            onClick={() => nextButtonClickHandler()}
            style={{
              height: 48,
              width: 48
            }}
          />
        </div>
        {brandsLoading ? (
          <h4 className="mb-3 uppercase font-semibold text-[20px]">
            <Skeleton
              width={300}
              height={30}
              style={{
                marginLeft: "-410px",
              }}
            />{" "}
          </h4>
        ) : (
          <div className="flex justify-between ">
            <h4 className="uppercase font-semibold text-[20px] mb-4">
              {strings.categories}
            </h4>

          </div>
        )}

        {brandsLoading ? (
          <div
            className="flex gap-[52px] flex-nowrap overflow-x-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-100"
            style={{ width: "100%" }}
          >
            {[1, 2, 3, 4].map((_, index) => (
              <div
                className="rounded-2xl shadow-md bg-white w-[200px]  flex-none mb-3 cursor-pointer"
                key={index}
              >
                <Skeleton
                  style={{
                    height: "200px",
                    width: "100%",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                  }}
                  className="max-h--sm h-60  shadow-sm"
                />
                <div className="text-center text-sm font-medium py-1">
                  <Skeleton height={10} width={50} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className='gap-4 flex p-2 bg-transparent overflow-y-auto overflow-x-auto scroll whitespace-nowrap scroll-smooth scrollbar-hide'
            ref={scrollContainerRef}
            onWheel={handleWheelScroll}
          >


            <div
              className="flex w-[400px] flex-col shadow-md rounded-xl  cursor-pointer "
              style={{
                transition: 'transform 0.3s',
                transform: `translateX(-${scrollPosition}px)`,
              }}

              onClick={() => {
                navigate(`/search-vehicle/all`);
              }}
            >
              <div className="w-[200px]">

                <img
                  src='https://egasi-storage.s3.ap-south-1.amazonaws.com/1730355933111_my899jij_card-removebg-preview.png'
                  alt="All"
                  className="h-[150px] w-full object-cover rounded-2xl shadow-sm"
                />
              </div>
              <h6 className="text-center text-sm font-extrabold py-1">
                {capitalizeFirstLetter(strings.all)} <span className="text-orange-400"> ({totalAds})</span>
              </h6>
            </div>

            {brands.sort((a, b) => a.sortedBy.localeCompare(b.sortedBy)).map((item, index) => (
              <div
                className="flex w-[400px] flex-col shadow-md rounded-xl  cursor-pointer "
                style={{
                  transition: 'transform 0.3s',
                  transform: `translateX(-${scrollPosition}px)`,
                }}

                key={index}
                onClick={() => {
                  navigate(`/search-vehicle/${item._id}`);
                }}
              >
                <div className="w-[200px]">

                  <img
                    src={item.imageUrl}
                    alt="Category"
                    className="h-[150px] w-full object-cover rounded-2xl shadow-sm"
                  />
                </div>
                <h6 className="text-center text-sm font-extrabold py-1">
                  {capitalizeFirstLetter(item.title)} <span className="text-orange-400"> ({item.totalAds || 0})</span>
                </h6>
              </div>
            ))}
          </div>
        )}
      </div>
      {brandsLoading ? (
        <div className="container mt-12">
          <Skeleton
            height={30}
            width={350}
            style={{
              marginBottom: "24px",
              marginLeft: "-385px",
            }}
          />

          <div className="">
            <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center">
              {[1, 2, 3, 4].map((_, index) => (
                <div
                  className="c shadow-xl rounded-xl  cursor-pointer "
                  key={index}
                >
                  <div className="relative h-48" >
                    <Skeleton
                      height={192}
                      style={{
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    />
                  </div>
                  <div className="px-[10px] mt-2" >
                    <div
                      className="flex items-center justify-between"

                    >
                      <Skeleton height={15} width={70} />
                      <Skeleton height={15} width={50} />
                    </div>
                    <div className="flex gap-2 " >
                      <Skeleton height={10} width={100} />
                    </div>
                    <div
                      className="flex mb-1  text-[10px] justify-between items-center text-gray-400"

                    >
                      <div className="flex gap-2 items-center">
                        <Skeleton height={10} width={20} />
                        <Skeleton height={10} width={20} />
                      </div>

                      <div className="flex gap-2 items-center">
                        <Skeleton height={10} width={20} />
                        <Skeleton height={10} width={20} />
                      </div>

                      <div className="flex gap-2 items-center">
                        <Skeleton height={10} width={20} />
                        <Skeleton height={10} width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="container mt-12">
          <div className="flex justify-between ">
            <h4 className="uppercase font-semibold text-[20px] mb-4">
              {strings.recommendedVehicles}
            </h4>
            <div className="flex">
              <ArrowBackIosNewIcon
                className={`cursor-pointer  rounded-full text-xl p-1 bg-white `}
                onClick={() => vehiclePrevButtonClickHandler()}
              />
              <ArrowForwardIosIcon
                className={`cursor-pointer  rounded-full text-xl p-1 ml-1 bg-white `}
                onClick={() => vehicleNextButtonClickHandler()}
              />
            </div>
          </div>
          <div className="">
            {vehicleData.length === 0 ? (
              <div className="flex p-0">
                <h3>{strings.norec}</h3>
              </div>
            ) : (
              <div
                className='flex gap-4 pb-2 bg-transparent overflow-y-auto overflow-hidden'
              >
                {vehicleData.map((item, index) => (
                  <div
                    className="w-[250px] h-[260px] min-w-[250px]  flex flex-col shadow-md rounded-xl  cursor-pointer "
                    style={{
                      display: 'flex',
                      transition: 'transform 0.3s',
                      transform: `translateX(-${vehicleScrollPosition}px)`,
                    }}
                    onClick={() => {
                      handleCarClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.basicInformation.model.title, item.location.city, item.location.district, item.basicInformation.KM)


                    }}
                  >
                    <div className="relative h-[170px] w-[100%]">
                      {!item?.fav?.includes(userId) ? (
                        <FavoriteBorderOutlinedIcon
                          className="absolute items-content-end w-12"
                          style={{
                            right: "8px",
                            color: "#F37521",
                            backgroundColor: "white",
                            borderRadius: 100,
                            padding: 3,
                            top: 8,
                          }}
                          onClick={(event) => {
                            if (user) {
                              addToFavorites(event, item._id);
                            }
                          }}
                        />
                      ) : (
                        <FavoriteOutlinedIcon
                          className="absolute items-content-end w-12 opacity-100"
                          style={{
                            right: "8px",
                            color: "red",
                            backgroundColor: "white",
                            borderRadius: 100,
                            padding: 3,
                            top: 8,
                          }}
                          onClick={(event) => {
                            if (user) {
                              addToFavorites(event, item?._id);
                            }
                          }}
                        />
                      )}
                      <img
                        className=" rounded-t-2xl"
                        src={item?.coverPhoto}
                        alt="random"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          // borderTopRightRadius: 12,
                          // borderTopLeftRadius: 12,
                        }}
                      />
                    </div>
                    <div className="px-[10px] mt-2">
                      <div
                        className="flex items-center justify-between"

                      >
                        <div>
                          <h3 className="font-[500] text-sm whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                            {item?.basicInformation?.title.length > 14
                              ? `${item.basicInformation.title.slice(0, 12)}...`
                              : item.basicInformation.title
                            }

                          </h3>
                        </div>

                        <div className="flex " style={{ color: "#F37521" }}>
                          <h1 className="font-bold text-sm ">
                            {item?.basicInformation?.price?.currency} &nbsp;
                          </h1>
                          <h1 className="font-bold text-sm">
                            {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </h1>

                        </div>
                      </div>
                      <div className="flex gap-2 -mb-2">
                        <h1 className="text-[9px] font-[350] text-gray-400 ">
                          {item?.basicInformation?.description?.slice(0, 25)}
                        </h1>
                      </div>
                      <div
                        className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"

                      >
                        <div className="flex gap-2 items-center">
                          <img src={year} alt="year" className="h-3 w-3" />
                          <p className=" mt-[17px]">
                            {item?.basicInformation?.details[0]?.value}
                          </p>
                        </div>

                        <div className="flex gap-2 items-center">
                          <img
                            src={engineSize}
                            alt="year"
                            className="h-3 w-3"
                          />
                          <p className=" mt-[17px]">
                            {item?.basicInformation?.details
                              ?.filter((item) =>
                                [
                                  "Engine Size",
                                  "Размер двигателя",
                                  "Dvigatel hajmi",
                                ].includes(item.fieldName)
                              )
                              .map((filteredItem, index) => (
                                <span key={index}>{filteredItem?.value}</span>
                              ))}
                          </p>
                        </div>

                        <div className="flex gap-2 items-center">
                          <img
                            src={calendar}
                            color="gray"
                            alt="year"
                            className="h-3 w-3 opacity-60"
                          />
                          <p className=" mt-[17px]">
                            {item?.basicInformation?.KM}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="container">
        <div className="row mt-4">
          <Carousel
            showIndicators={false}
            showThumbs={false}
            interval={3000}
            autoPlay={true}
            infiniteLoop
          >

            {
              ads.filter((ad) => ad.imageUrl).map((item, index) => (
                <div className="md:h-[200px] h-[100px]">
                  <img src={item?.imageUrl} className="rounded-md h-[100%] object-cover " />
                </div>
              ))
            }
          </Carousel>


        </div>
      </div>

      {/*......6......*/}
      <div className="container">
        <div
          className="contain md:m"
          style={{
            background: "white",
            marginTop: "40px",
            borderRadius: "10px",
          }}
        >
          <div className="flex justify-content-center pt-[20px] underline ">

            {strings.whatDo}
          </div>


          <div className="row section">
            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11921} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findPlace}
              </h6>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11922} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findVehicle}
              </h6>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11923} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.buyRent}
              </h6>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11924} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.listAdd}
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* Partners ..............7....... */}
      <div className="container ">
        <h5 className="p-0 m-0 font-semibold text-[20px]">{strings.ourP}</h5>
        <div className="flex justify-center overflow-x-scroll">
          {
            partners?.map((item, index) => (
              <div className="rounded   px-4  mt-4" key={index}>
                <img className="w-32 h-32" src={item.imageUrl} alt="" />
              </div>
            ))
          }


        </div>
      </div>


      <Footer strings={strings} />
    </div>
  );
};

export default Body;
