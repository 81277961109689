import React, { useState, useEffect } from "react";
import print from "../../images/print.svg";
import teer from "../../images/teer.svg";
import ws from "../../images/ws.png";
import msg from "../../images/msg.png";
import phones from "../../images/phone.png";
import km from '../../images/km.png'
import send from "../../images/send.png";
import MaskGroup from "../../images/Mask Group 1.png";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import "./index.css";
import strings from "../../utils/locals/languages";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import exportToPdf from "./pdf";
import { toast } from "react-toastify";
import apiClient from "../../utils/apiClient";
import moment from "moment";
import idIcon from "../../images/id.svg";
import model from "../../images/model.svg";
import brand from "../../images/brand.svg";
import calendar from "../../images/calendar.svg";
import "./index.css";
import Map from "./Map";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Cookies from "js-cookie";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import { Avatar } from "@mui/material";
import SimilarAds from "./SimilarAds";

const Body = ({ innerdetails }) => {

  let userPhoneNumber;
  let userEmail;

  const user = Cookies.get("userDetails");

  if (user) {
    try {
      const userDetails = JSON.parse(user);

      userPhoneNumber = userDetails?.phone;
      userEmail = userDetails?.email;


    } catch (error) {
    }
  } else {
  }

  const [selectedImage, setSelectedImage] = useState();
  const [isVideo, setIsVideo] = useState(false);

  const [isSelected, setIsSelected] = useState(-1);
  const [isFullScreenImage, setIsFullScreenImage] = useState(false)

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("tab1");
  const [isFav, setIsFav] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [updatedDetails, setUpdatedDetails] = useState(innerdetails?.basicInformation?.details)
  const [userId, setUserId] = useState("");
  const [isCoverPhotoSelected, setIsCoverPhotoSelected] = useState(true)


  const nextButtonClickHandler = () => {
    if (((innerdetails.imageUrl.length) - 1) > isSelected) {
      setIsSelected(isSelected + 1)
      setIsCoverPhotoSelected(false)
      setSelectedImage(innerdetails.imageUrl[isSelected + 1])

    } else {
      setIsSelected((innerdetails.imageUrl.length))
      setIsVideo(true)
    }
  };

  useEffect(() => {
    if (innerdetails.videoUrl === "") {
      setIsSelected(-1)
      setIsVideo(false)
    }
  }, [isVideo])

  const prevButtonClickHandler = async () => {
    if (isSelected !== -1) {
      setIsVideo(false)
      setIsSelected(isSelected - 1)
      setSelectedImage(innerdetails.imageUrl[isSelected - 1])
    } else {
      setIsCoverPhotoSelected(true)
      setSelectedImage(innerdetails.coverPhoto)

    }
  };

  useEffect(() => {
    if (isSelected === -1) {
      setIsCoverPhotoSelected(true)
    }
  }, [isSelected])

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const addToFavoritesGeneral = (event, adId) => {

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .catch((err) => { });
  };

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);



  const sendWtpMessage = (phoneNumber) => {
    const message = "From Egasi website!"; // Replace with the message you want to send
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = url;
  };

  const sendSMS = (phoneNumber) => {
    const url = `sms:${phoneNumber}`;
    window.open(url);
  };


  const handleCopyLink = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    let deepLink;

    if (isIOS) {
      deepLink = "bundlename://linkname";
    } else if (isAndroid) {
      deepLink = "bundlename://linkname";
    } else {
      deepLink = window.location.href;
    }

    const el = document.createElement("input");
    el.value = deepLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    toast(`${strings.linkCopied}`);
  };

  const navigateToTelegram = (userName) => {
    const telegramUrl = `https://t.me/${userName}`;

    window.location.href = telegramUrl;
  };

  const phoneCall = (phoneNumber) => {
    const phoneCallUrl = `tel:${phoneNumber}`;

    window.location.href = phoneCallUrl;
  };

  const geopoints = [
    {
      coordinates: {
        lat: innerdetails?.location?.geoPoints?.coordinates[1],
        lng: innerdetails?.location?.geoPoints?.coordinates[0]
      },
      price: `${innerdetails?.basicInformation?.price?.currency} ${innerdetails?.basicInformation?.price?.price}`,
      item: innerdetails,
    }
  ]

  useEffect(() => {
    if (innerdetails) {
      setSelectedImage(innerdetails?.coverPhoto)
    }
  }, [])

  useEffect(() => {
    if (innerdetails?.fav?.length === 0) {
      setIsFav(false);
    } else {
      setIsFav(true);
    }
  }, []);

  return (
    <div>
      {/* ..............Price and category section.............. */}
      <div className="container ">
        <div className="row">
          <div className="flex  ">
            <div className="md:flex col-8 justify-between ">
              <div className="">
                <h4 className="font-semibold text-[20px]">
                  {innerdetails?.basicInformation?.title}
                </h4>
                <p style={{ color: "#5BA8FF", fontSize: "12px" }}
                  onClick={() => navigate(`/search-vehicle/${innerdetails?.basicInformation?.category?._id}`)}
                  className="cursor-pointer"
                >
                  {innerdetails?.basicInformation?.category?.title}
                  {" > "}
                  <span
                    onClick={() => navigate(`/search-vehicle/${innerdetails?.basicInformation?.category?._id}`)}
                    className="cursor-pointer"
                  >
                    {innerdetails?.basicInformation?.brand?.title}
                  </span>
                  {" > "}
                  {innerdetails?.basicInformation?.model?.title}

                </p>
              </div>

              <div className="">
                <h3
                  style={{
                    color: "#F37521",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  className="flex md:justify-end  lg:justify-start"
                >
                  {innerdetails?.basicInformation?.price?.currency}{" "}
                  {Number(innerdetails?.basicInformation?.price?.price).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )}
                </h3>
                <p

                  className="text-[12px] text-[#5BA8FF]"
                >
                  {innerdetails?.location?.city}
                  {" > "}
                  {innerdetails?.location?.district}

                </p>{" "}
              </div>

            </div>
            <div className="md:flex hidden lg:flex justify-end items-start col-4 ">
              <div className="spans flex">
                {!isFav ? (
                  <FavoriteBorderOutlinedIcon
                    style={{
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      marginTop: 4,
                    }}
                    onClick={(e) => {
                      if (user) {
                        if (!isFav) {
                          setIsFav(true);
                        } else {
                          setIsFav(false);
                        }
                        addToFavoritesGeneral(e, innerdetails._id);
                      }
                    }}
                  />
                ) : (
                  <FavoriteOutlinedIcon
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "red",
                      cursor: "pointer",
                      marginTop: 4,
                    }}
                    onClick={(e) => {
                      if (user) {
                        if (!isFav) {
                          setIsFav(true);
                        } else {
                          setIsFav(false);
                        }
                        addToFavoritesGeneral(e, innerdetails._id);
                      }
                    }}
                  />
                )}
                <img
                  src={print}
                  alt=""
                  style={{ width: "50px", cursor: "pointer" }}
                  onClick={() => {
                    exportToPdf(innerdetails);
                  }}
                />
                <img
                  src={teer}
                  alt=""
                  className="w-[50px] cursor-pointer -mr-4"
                  onClick={handleCopyLink}
                />
              </div>
            </div>
          </div>

          <div className="col-12 md:hidden lg:hidden col-md-4 col-lg-2 ">
            <div className="spanHs ">
              {!isFav ? (
                <FavoriteBorderOutlinedIcon
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                    marginTop: 4,
                  }}
                  onClick={() => {
                    if (!isFav) {
                      setIsFav(true);
                    } else {
                      setIsFav(false);
                    }
                    addToFavoritesGeneral();
                  }}
                />
              ) : (
                <FavoriteOutlinedIcon
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "red",
                    cursor: "pointer",
                    marginTop: 4,
                  }}
                  onClick={() => {
                    if (!isFav) {
                      setIsFav(true);
                    } else {
                      setIsFav(false);
                    }
                    addToFavoritesGeneral();
                  }}
                />
              )}
              <img
                src={print}
                alt=""
                style={{ width: "50px", cursor: "pointer" }}
                onClick={() => {
                  exportToPdf(innerdetails);
                }}
              />
              <img
                src={teer}
                alt=""
                style={{ width: "50px", cursor: "pointer" }}
                onClick={handleCopyLink}
              />
            </div>
          </div>
        </div>
      </div>
      {/*............. Images and contact info container.....................*/}
      <div className="container ">
        <div className="md:flex justify-between gap-[10px] ">
          <div className="col-12 col-md-6 col-lg-8 ">
            <div
              className="w-[100%] flex justify-center"
            >

              {
                isVideo ? (
                  <video
                    controls
                    className="h-[500px]"
                    style={{ width: "100%", borderRadius: 8 }}
                  >
                    <source src={innerdetails?.videoUrl} />
                  </video>
                ) : (
                  isCoverPhotoSelected ? (
                    <img
                      src={innerdetails.coverPhoto}
                      className="md:h-[500px] w-auto h-[250px] rounded-lg "
                      onClick={() => setIsFullScreenImage(true)}
                    />
                  ) : (
                    <img
                      src={selectedImage}
                      className="md:h-[500px] w-auto h-[250px] rounded-lg "
                      onClick={() => setIsFullScreenImage(true)}
                    />
                  )
                )
              }

            </div>
            <div className="overflow-x-auto flex items-center pt-1 mt-2 -ml-1">

              <img
                src={innerdetails.coverPhoto}
                className={`mr-[13px] h-[80px] w-[90px] rounded-[8px] ${isCoverPhotoSelected && 'border-[2px] border-[#F37521]'} `}
                onClick={() => {
                  setIsCoverPhotoSelected(true)
                  setIsSelected(-1)
                }}
              />
              <div
                className="gap-2 flex justify-start"
                style={{
                  width: `${innerdetails?.imageUrl?.length * 150}px`,
                  transform: `translateX(-${scrollPosition}px)`,
                  transition: "transform 0.3s",
                }}
              >

                {innerdetails?.imageUrl?.length !== 0 &&
                  innerdetails?.imageUrl?.map((item, index) => (
                    <div
                      key={index}
                      className='h-[80px] w-[90px] p-1 rounded-[7px] cursor-pointer'

                      onClick={() => {
                        setIsVideo(false);
                        setSelectedImage(item);
                        setIsCoverPhotoSelected(false)
                        setIsSelected(index);
                      }}
                    >
                      <img
                        src={item}
                        className={`  h-[80px] w-[90px]  rounded-[8px] ${isSelected === index && 'border-[2px] border-[#F37521]'} `}

                      />
                    </div>
                  ))}
                {innerdetails?.videoUrl !== "" && (
                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1GgiulqxH12vBGPxV7GxRQEsRtj2B1w2yew&s'
                    className={`h-[90px] w-[90px]  rounded-[8px] ${isVideo && 'border-[2px] border-[#F17522]'}`}
                    onClick={() => {
                      if (!isVideo) {
                        setIsVideo(true);
                      }
                    }}
                  />
                )}
              </div>
            </div>

            {isFullScreenImage && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 99
                }}
                onClick={() => setIsFullScreenImage(false)}
              >
                <img
                  src={selectedImage}
                  alt={`Full Screen Image ${selectedImage}`}
                  style={{
                    maxHeight: "90vh",
                    maxWidth: "90vw",
                    zIndex: 99
                  }}
                  onClick={() => setIsFullScreenImage(false)}
                />
              </div>
            )}

            <div className="w-full lg:mb-0 md:mb-0 mb-3 flex justify-center mt-2">
              <div
                style={{ display: "flex", cursor: "pointer" }}
                className="gap-2"
              >
                <ArrowBackIosNewIcon
                  onClick={() => prevButtonClickHandler()}
                  style={{
                    backgroundColor: "#fff",
                    padding: 5,
                    borderRadius: 50,
                  }}
                />
                <ArrowForwardIosIcon
                  onClick={() => nextButtonClickHandler()}
                  style={{
                    backgroundColor: "#fff",
                    padding: 5,
                    borderRadius: 50,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4"
          >


            {/* contact information */}
            <div className="md:ml-3 md:mt-0 lg:mt-0 sm:mt-5 lg:ml-3 ml-0">
              <div
                className="bg-white  px-2  shadow-md pt-3"
                style={{ borderRadius: "10px" }}
              >
                <div>
                  <div className=" font-semibold text-[12px]  h-[80px]">
                    <p className="uppercase">{strings.contactInfo}</p>

                    <div className="flex w-full justify-between">
                      <p className="text-[10px]"> {
                        innerdetails?.basicInformation?.contactInformation
                          ?.nameSurname
                      }</p>
                      <Avatar
                        style={{
                          height: 80,
                          width: 80,
                        }}
                        src={innerdetails?.user?.image}
                        className="-mt-4 rounded-full items-end mr-5"
                      />

                    </div>

                  </div>

                </div>

                <div className="text-black text-[12px]">{innerdetails?.user?.phone}</div>
                <br />
                <div
                  className="d-flex justify-center "

                >
                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("whatsapp") && (
                      <img
                        src={ws}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => sendWtpMessage(innerdetails?.user?.phone)}
                      />
                    )}

                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("sms") && (
                      <img
                        src={msg}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => sendSMS(innerdetails?.user?.phone)}
                      />
                    )}
                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("call") && (
                      <img
                        src={phones}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => phoneCall(innerdetails.user.phone)}
                      />
                    )}
                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("telegram") && (
                      <img
                        src={send}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => navigateToTelegram(innerdetails?.user?.name)}
                      />
                    )}

                </div>
              </div>
              {!(
                innerdetails?.user &&
                (innerdetails?.user?.phone === userPhoneNumber ||
                  innerdetails?.user?.email === userEmail)
              ) && (

                  <div
                    className=" w-full text-xs bg-[#F17522] cursor-pointer p-[10px] text-center rounded-lg text-white uppercase mt-2 "
                    onClick={() => {
                      if (innerdetails.user === null) {
                        toast.error("Ad User not found");
                      } else {
                        if (Cookies.get("userDetails") === null || Cookies.get("userDetails") === undefined) {
                          toast.warn(`${strings.pleaseSignIn}`)
                        } else {
                          navigate("/reportAd", {
                            state: {
                              innerdetails: {
                                adds: innerdetails._id,
                                name: innerdetails.user.name,
                                email: innerdetails.user.email,
                                imageUrl: "",
                                title: innerdetails.basicInformation.title,
                                reportType: "",
                                detail: "",
                              },
                            },
                          });
                        }

                      }
                    }}
                  >
                    {strings.reportad}
                  </div>
                )}
              <img
                className="mt-3 w-full hidden md:block lg:block shadow-md"
                src={MaskGroup}
                alt=""
                style={{ height: "400px", borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      </div>



      {/*...............Details Sect...................*/}
      <div className="container ">
        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-8 ">
            <div className="box ">
              <div
                className="row bg-white rounded-md"
                style={{
                  boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                }}
              >
                <div className="col-12 col-md-6 col-lg-9 d-flex align-middle ">
                  <div className="flex p-1">
                    <button
                      className="py-1 px-4 rounded-md justify-center flex flex-col text-center md:h-12 lg:h-12  flecol"
                      onClick={() => {
                        handleTabClick("tab1");
                      }}
                      style={{
                        backgroundColor:
                          activeTab === "tab1" ? "#f37521" : "transparent",
                        color: activeTab === "tab1" ? "#fff" : "#000",
                      }}
                    >
                      <EventNoteOutlinedIcon
                        style={{
                          display: window.innerWidth > 768 ? "none" : "block",
                          marginLeft: "14px",
                        }}
                      />
                      <div>{strings.moreDetails}</div>
                    </button>
                    {
                      (innerdetails.basicInformation.category._id !== '63f723aae53fdd92a8452ec4' &&
                        innerdetails.basicInformation.category._id !== '63f722eee53fdd92a84526ed' &&
                        innerdetails.basicInformation.category._id !== '63f71a6fe53fdd92a844cf22')
                      && (
                        <button
                          style={{
                            backgroundColor:
                              activeTab === "tab2" ? "#f37521" : "transparent",
                            color: activeTab === "tab2" ? "#fff" : "#000",
                          }}
                          className="py-1 px-4 rounded-md md:h-12 lg:-12 flexol "
                          onClick={() => {
                            handleTabClick("tab2");
                          }}
                        >
                          <FormatListNumberedOutlinedIcon
                            style={{
                              display: window.innerWidth > 768 ? "none" : "block",
                              marginLeft: "14px",
                            }}
                          />
                          <div>{strings.features}</div>
                        </button>
                      )
                    }
                    <button
                      style={{
                        backgroundColor:
                          activeTab === "tab3" ? "#f37521" : "transparent",
                        color: activeTab === "tab3" ? "#fff" : "#000",
                      }}
                      className="py-1 px-4 rounded-md md:h-12 lg:md-12 fx-col  "
                      onClick={() => handleTabClick("tab3")}
                    >
                      <PlaceOutlinedIcon
                        style={{
                          display: window.innerWidth > 768 ? "none" : "block",
                          marginLeft: "14px",
                        }}
                      />
                      <div> {strings.location}</div>
                    </button>
                  </div>
                </div>
              </div>

              {activeTab === "tab1" && (
                <div className="row ">
                  <div className=" flex mt-3 w-full px-0 justify-between ">
                    <div className="border w-[48%] bg-gray-200 shadow-md rounded-lg mt-2 mb-2 overflow-hidden">
                      <div
                        className={`flex justify-between p-2 px-3  bg-white
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <img
                            className="w-5 h-5 "
                            src={idIcon}
                            alt=""
                          />
                          <h1 className="text-[12px] mt-1 ">
                            ID
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px] capitalize mt-1">
                            {innerdetails?.customId}
                          </h1>
                        </div>
                      </div>

                      <div
                        className={`flex justify-between p-2 px-3
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <img
                            className="w-5 h-5 "
                            src={calendar}
                            alt=""
                          />
                          <h1 className="text-[12px]  mt-1 ">
                            {capitalizeFirstLetter(strings.listingDate)}
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px]  capitalize mt-1">
                            {moment(innerdetails?.createdAt)?.format("DD/MM/YYYY")}
                          </h1>
                        </div>
                      </div>

                      <div
                        className={`flex justify-between p-2 px-3  bg-white
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <img
                            className="w-5 h-5 "
                            src={brand}
                            alt=""
                          />
                          <h1 className="text-[12px] mt-1 ">
                            {capitalizeFirstLetter(strings.brand)}
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px] capitalize mt-1">
                            {innerdetails?.basicInformation?.brand?.title}
                          </h1>
                        </div>
                      </div>


                      <div
                        className={`flex justify-between p-2 px-3 
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <img
                            className="w-5 h-5 "
                            src={model}
                            alt=""
                          />
                          <h1 className="text-[12px] mt-1 ">
                            {strings.model}
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px] capitalize mt-1">
                            {innerdetails?.basicInformation?.model?.title}
                          </h1>
                        </div>
                      </div>
                      {
                        (innerdetails?.basicInformation?.category?._id !== '63f72212e53fdd92a84520d0' &&
                          innerdetails?.basicInformation?.category?._id !== '63f71edfe53fdd92a844f321' &&
                          innerdetails?.basicInformation?.KM) && (
                          <div
                            className="flex justify-between p-2 px-3 bg-white"
                          >
                            <div className="flex ite gap-[10px]">
                              <img
                                className="w-5 h-5"
                                src={km}
                                alt=""
                              />
                              <h1 className="text-[12px] mt-1">
                                KM
                              </h1>
                            </div>
                            <div>
                              <h1 className="text-[12px] capitalize mt-1">
                                {innerdetails?.basicInformation?.KM}
                              </h1>
                            </div>
                          </div>
                        )
                      }


                      <div
                        className={`flex justify-between p-2 px-3
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <img
                            className="w-5 h-5 "
                            src='https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560314529'
                            alt=""
                          />
                          <h1 className="text-[12px] mt-1 ">
                            {strings.purpose}
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px] mt-1">
                            {innerdetails.purpose}
                          </h1>
                        </div>
                      </div>
                      {updatedDetails?.slice(0, ((updatedDetails.length / 2) - 3)).map((item, index) => (
                        <div
                          className={`flex justify-between p-2 px-3  ${index % 2 === 0 ? "bg-white" : ""
                            }`}
                        >
                          <div className="flex items-center justify-center gap-[10px]">
                            <img
                              className="w-5 h-5 "
                              src={item?.imageUrl}
                              alt=""
                            />
                            <h1 className="text-[12px] mt-1 ">
                              {
                                capitalizeFirstLetter(item?.fieldName)
                              }
                            </h1>
                          </div>
                          <div className="w-[30%] flex items-center justify-end">
                            <h1 className=" text-[12px] capitalize mt-1">
                              {
                                capitalizeFirstLetter(item?.value)
                              }
                            </h1>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="border w-[48%] bg-gray-200 shadow-md rounded-lg mt-2 mb-2 overflow-hidden">
                      {updatedDetails && updatedDetails?.slice(((updatedDetails.length / 2) - 3))?.map((item, index) => (
                        <div
                          className={`flex justify-between p-2 px-3  item ${index % 2 === 0 ? "bg-white" : ""
                            }`}
                        >
                          <div className="flex ite gap-[10px]">
                            <img
                              className="w-5 h-5 "
                              src={item.imageUrl}
                              alt=""
                            />
                            <h1 className="text-[12px] mt-1 ">
                              {
                                item.fieldName && capitalizeFirstLetter(item.fieldName)
                              }

                            </h1>
                          </div>

                          <div>
                            <h1 className=" text-[12px] mt-1">
                              {item.value === "true"
                                ? "Yes"
                                : item.value === "false"
                                  ? "No"
                                  : item.fieldName && capitalizeFirstLetter(item.value)}
                            </h1>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-3 w-full px-0">
                    <h2
                      className="text-lg ml-2  mt-2"
                      style={{ textAlgin: "start" }}
                    >
                      {strings.bodyParts}
                    </h2>

                    <div className="border m-1 bg-white shadow-lg rounded-lg mt-4 overflow-hidden">
                      {innerdetails?.bodyParts?.length !== 0 &&
                        innerdetails?.bodyParts?.map((item, index) => (
                          <div
                            className={`flex justify-between p-2 px-3 items- ${index % 2 === 0 ? "bg-gray-200" : ""
                              }`}
                          >
                            <div className="flex gap-5">
                              <h1 className="text-[12px] mt-1 ">
                                {item.fieldName}
                              </h1>
                            </div>

                            <div>
                              <h1 className="text-[12px] capitalize mt-1 ">
                                {item.value}
                              </h1>
                            </div>
                          </div>
                        ))}
                    </div>

                  </div>

                  <div className="mt-3 w-full px-0">
                    <h2
                      className="text-lg ml-2  mt-2"
                      style={{ textAlgin: "start" }}
                    >
                      {strings.desc}
                    </h2>

                    <p className="text-xs ml-2">
                      {innerdetails?.basicInformation?.description}
                    </p>
                  </div>
                </div>
              )}

              {activeTab === "tab2" && (
                <>
                  <div>
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.extFeatures}
                    </h4>

                    <div className="grid grid-cols-3 gap-1  md:grid-cols-3 lg:grid-cols-7 ">
                      {innerdetails?.externalFeatures?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item) => (
                        <div className="bg-white gap-1 rounded-lg w-24  text-center justify-center flex flex-col shadow-md p-1  items-center  feature-st">
                          <img
                            className="w-  h-8 mt-1"
                            src={item.imageUrl}
                            alt=""
                          />

                          <p
                            className="text-center -mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {item.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.internalFeatures}
                    </h4>

                    <div className="grid grid-cols-3  gap-1 md:grid-cols-4 lg:grid-cols-7 ">
                      {innerdetails?.internalFeatures?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((internal) => (
                        <div className="bg-white gap-1 rounded-lg w-24  text-center justify-center flex flex-col shadow-md p-1  items-center ure-styles">
                          <img
                            className="w- mt-1 h-8 w-8"
                            src={internal.imageUrl}
                            alt=""
                          />
                          <p
                            className="text-center mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {internal.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.safetyFeatures}
                    </h4>
                    <div className="grid grid-cols-3 gap-1  md:grid-cols-3 lg:grid-cols-7">
                      {innerdetails?.safetyFeatures?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((Safety) => (
                        <div className=" bg-white gap-1 rounded-lg w-24 text-center justify-center flex flex-col shadow-md p-1   items-center p feature">
                          <div className="flex justify-center">
                            <img
                              className="w-8 mt-1 h-8"
                              src={Safety.imageUrl}
                              alt=""
                            />
                          </div>
                          <p
                            className="text-center mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {Safety.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {activeTab === "tab3" && (
                <>
                  <h1 className="font-semibold text-lg mt-4">
                    {strings.location}
                  </h1>
                  <div className="flex justify gap-5 text-sm     h-6">
                    <p className="w-28">{strings.country}</p>
                    <p>{innerdetails.location.country}</p>
                  </div>

                  <div className="flex gap-5 text-sm justify  h-6">
                    <p className="w-28">{strings.city}</p>
                    <p>{innerdetails.location.city}</p>
                  </div>

                  <div className="flex text-sm gap-5 justify-content- mb-2     h-6">
                    <p className="w-28">{strings.distt}</p>
                    <p>{innerdetails.location.district}</p>
                  </div>

                  <div
                    className="flex justify-center"
                    style={{ height: "100px", width: '100%' }}
                  >
                    <Map geopoints={geopoints} />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Similar Ads */}
          <SimilarAds innerdetails={innerdetails} />

        </div>
      </div>
      <Footer strings={strings} />
    </div >
  );
};

export default Body;



