import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { baseURL1, realEstateId } from "../../utils/baseUrl";
import strings from "../../utils/locals/languages";
import print from "../../images/print.svg";
import teer from "../../images/teer.svg";
import MaskGroup from "../../images/Mask Group 1.png";
import ws from "../../images/ws.png";
import msg from "../../images/msg.png";
import phones from "../../images/phone.png";
import send from "../../images/send.png";
import exportToPdf from "./pdf";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import apiClient from "../../utils/apiClient";
import { useSelector } from "react-redux";
import Map from "./Map";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import idIcon from "../../images/id.svg";
import calendar from "../../images/calendar.svg";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";
import Cookies from "js-cookie";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import { Avatar } from "@mui/material";
import { handleRealEstateClick } from "../../services/handlers";


const Body = ({ innerdetails }) => {

  let userPhoneNumber;
  let userEmail;

  const user = Cookies.get("userDetails");

  if (user) {
    try {
      const userDetails = JSON.parse(user);

      userPhoneNumber = userDetails?.phone;
      userEmail = userDetails?.email;

    } catch (error) {
    }
  } else {
  }

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);


  const [selectedImage, setSelectedImage] = useState(innerdetails.imageUrl[0]);
  const [isVideo, setIsVideo] = useState(false);
  const [isCoverPhotoSelected, setIsCoverPhotoSelected] = useState(true)


  const [isSelected, setIsSelected] = useState(-1);

  const [isFav, setIsFav] = useState(false);
  const [recommendedData, setRecommendedData] = useState([]);
  const [recommendedDataLoading, setRecommendedDataLoading] = useState(true);

  const [scrollPosition, setScrollPosition] = useState(0);



  const nextButtonClickHandler = () => {
    if (((innerdetails.imageUrl.length) - 1) > isSelected) {
      setIsSelected(isSelected + 1)
      setIsCoverPhotoSelected(false)
      setSelectedImage(innerdetails.imageUrl[isSelected + 1])

    } else {
      setIsSelected((innerdetails.imageUrl.length))
      setIsVideo(true)
    }
  };

  const prevButtonClickHandler = async () => {
    if (isSelected !== -1) {
      setIsVideo(false)
      setIsSelected(isSelected - 1)
      setSelectedImage(innerdetails.imageUrl[isSelected - 1])
    } else {
      setIsCoverPhotoSelected(true)
      setSelectedImage(innerdetails.coverPhoto)

    }
  };

  useEffect(() => {
    if (isSelected === -1) {
      setIsCoverPhotoSelected(true)
    }
  }, [isSelected])


  useEffect(() => {
    if (innerdetails.videoUrl === "") {
      setIsSelected(-1)
      setIsVideo(false)
    }
  }, [isVideo])


  const language = useSelector((state) => state.language);

  const [isFullScreenImage, setIsFullScreenImage] = useState(false)

  const addToFavoritesGeneralGeneral = () => {
    apiClient
      .get(`/v1/user/fav-unfav/${innerdetails._id}`)
      .then((res) => {
        // toast.success(res.data.msg)
      })
      .catch((err) => {
        // toast.error(`${strings.failedToAdd}`)
      });
  };

  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  // tab section end

  const navigate = useNavigate();
  const sendWtpMessage = (phoneNumber) => {
    const message = "From Egasi website!"; // Replace with the message you want to send
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = url;
  };

  const sendSMS = (phoneNumber) => {
    const url = `sms:${phoneNumber}`;
    window.open(url);
  };


  const handleCopyLink = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);
    let deepLink;

    if (isIOS) {
      deepLink = "bundlename://linkname";
    } else if (isAndroid) {
      deepLink = "bundlename://linkname";
    } else {
      deepLink = window.location.href;
    }

    const el = document.createElement("input");
    el.value = deepLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    toast(`${strings.linkCopied}`);
  };

  const navigateToTelegram = (userName) => {
    const telegramUrl = `https://t.me/${userName}`;

    window.location.href = telegramUrl;
  };

  const phoneCall = (phoneNumber) => {
    const phoneCallUrl = `tel:${phoneNumber}`;

    window.location.href = phoneCallUrl;
  };

  const addToFavoritesGeneral = (event, adId) => {

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        apiClient
          .get(
            `${baseURL1}/v1/adds/search/?section=${realEstateId}&language=${strings._language}`
          )
          .then((response) => {
            setRecommendedData(response.data.data);
            setRecommendedDataLoading(false);
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  const geopoints = [
    {
      coordinates: {
        lat: innerdetails.location.geoPoints.coordinates[1],
        lng: innerdetails.location.geoPoints.coordinates[0]
      },
      price: `${innerdetails.basicInformation.price.currency} ${innerdetails.basicInformation.price.price}`,
      item: innerdetails,
    }
  ]

  useEffect(() => {
    if (innerdetails.fav.length === 0) {
      setIsFav(false);
    } else {
      setIsFav(true);
    }
  }, []);



  const searchFilter = () => {
    setRecommendedDataLoading(true);
    apiClient
      .get(
        `/v1/adds/search/?section=638f25154cb7c29ec6ad4bfe&language=${strings._language}&purpose=${innerdetails.purpose}
            `
      )
      .then((response) => {
        setRecommendedData(response.data.data);
        setRecommendedDataLoading(false);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (innerdetails) {
      setSelectedImage(innerdetails?.coverPhoto)
    }
  }, [])

  useEffect(() => {
    searchFilter();
  }, [strings._language]);

  const [updatedDetails, setUpdatedDetails] = useState(innerdetails?.basicInformation.details.sort())
  useEffect(() => {
    const sortedData = innerdetails?.basicInformation.details.sort((a, b) => {
      // Compare numbers directly
      return a.sortBy - b.sortBy;
    });

    setUpdatedDetails(sortedData);
  }, [innerdetails]);




  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="flex  ">
            <div className="md:flex col-8 justify-between ">
              <div className="">
                <h4 className="font-semibold text-[20px]">
                  {innerdetails.basicInformation.title}
                </h4>

                <p style={{ color: "#5BA8FF", fontSize: "12px" }}
                  onClick={() => navigate(`/search-realestate/${innerdetails?.basicInformation?.category?._id}`)}
                  className="cursor-pointer"
                >
                  {strings.realEstates}{" > "}
                  <span
                    onClick={() => navigate(`/search-realestate/${innerdetails?.basicInformation?.category?._id}`)}
                    className="cursor-pointer"
                  >
                    {innerdetails?.basicInformation?.category?.title}
                  </span>
                  {" > "}
                  {innerdetails.basicInformation.brand.title}
                </p>
              </div>

              <div className="">
                <h3
                  style={{
                    color: "#F37521",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  className="flex md:justify-start lg:justify-start"
                >
                  {innerdetails.basicInformation.price.currency}{" "}
                  {Number(innerdetails.basicInformation.price.price).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )}
                </h3>
                <p

                  className="text-[12px] text-[#5BA8FF]"
                >
                  {innerdetails.location.city}
                  {" > "}
                  {innerdetails.location.district}
                </p>{" "}
              </div>
            </div>
            <div className="md:flex hidden lg:flex justify-end items-start col-4 ">
              <div className="spans flex">
                {!isFav ? (
                  <FavoriteBorderOutlinedIcon
                    style={{
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      marginTop: 4,
                    }}
                    onClick={() => {
                      if (user) {
                        if (!isFav) {
                          setIsFav(true);
                        } else {
                          setIsFav(false);
                        }
                        addToFavoritesGeneralGeneral();
                      }
                    }}
                  />
                ) : (
                  <FavoriteOutlinedIcon
                    style={{
                      width: "40px",
                      height: "40px",
                      color: "red",
                      cursor: "pointer",
                      marginTop: 4,
                    }}
                    onClick={() => {
                      if (user) {
                        if (!isFav) {
                          setIsFav(true);
                        } else {
                          setIsFav(false);
                        }
                        addToFavoritesGeneralGeneral();
                      }
                    }}
                  />
                )}
                <img
                  src={print}
                  alt=""
                  style={{ width: "50px", cursor: "pointer" }}
                  onClick={() => {
                    exportToPdf(innerdetails);
                  }}
                />
                <img
                  src={teer}
                  alt=""
                  className="w-[50px] cursor-pointer -mr-4"
                  onClick={handleCopyLink}
                />
              </div>
            </div>
          </div>

          <div className="col-12 md:hidden lg:hidden col-md-4 col-lg-2 ">
            <div className="spanHs ">
              {!isFav ? (
                <FavoriteBorderOutlinedIcon
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                    marginTop: 4,
                  }}
                  onClick={() => {
                    if (!isFav) {
                      setIsFav(true);
                    } else {
                      setIsFav(false);
                    }
                    addToFavoritesGeneralGeneral();
                  }}
                />
              ) : (
                <FavoriteOutlinedIcon
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "red",
                    cursor: "pointer",
                    marginTop: 4,
                  }}
                  onClick={() => {
                    if (!isFav) {
                      setIsFav(true);
                    } else {
                      setIsFav(false);
                    }
                    addToFavoritesGeneralGeneral();
                  }}
                />
              )}
              <img
                src={print}
                alt=""
                style={{ width: "50px", cursor: "pointer" }}
                onClick={() => {
                  exportToPdf(innerdetails);
                }}
              />
              <img
                src={teer}
                alt=""
                style={{ width: "50px", cursor: "pointer" }}
                onClick={handleCopyLink}
              />
            </div>
          </div>
        </div>
      </div>

      {/*............. Images and contact info container.....................*/}
      <div className="container ">

        <div className="md:flex justify-between gap-[10px] ">
          <div className="col-12 col-md-6 col-lg-8">
            <div
              className="w-[100%] flex justify-center"
            >
              {
                isVideo ? (
                  <video
                    controls
                    className="h-[500px]"
                    style={{ width: "100%", borderRadius: 8 }}
                  >
                    <source src={innerdetails?.videoUrl} />
                  </video>
                ) : (
                  isCoverPhotoSelected ? (
                    <img
                      src={innerdetails.coverPhoto}
                      className="md:h-[500px] w-auto h-[250px] rounded-lg "
                      onClick={() => setIsFullScreenImage(true)}
                    />
                  ) : (
                    <img
                      src={selectedImage}
                      className="md:h-[500px] w-auto h-[250px] rounded-lg "
                      onClick={() => setIsFullScreenImage(true)}
                    />
                  )
                )
              }
            </div>
            <div className="overflow-x-auto flex items-center pt-1 mt-2 -ml-1">
              <img
                src={innerdetails.coverPhoto}
                className={`mr-[13px] h-[80px] w-[90px] rounded-[8px] ${isCoverPhotoSelected && 'border-[2px] border-[#F37521]'} `}


                onClick={() => {
                  setIsCoverPhotoSelected(true)
                  setIsSelected(-1)
                }}
              />
              <div
                className="gap-2 flex justify-start"
                style={{
                  width: `${innerdetails.imageUrl.length * 150}px`,
                  transform: `translateX(-${scrollPosition}px)`,
                  transition: "transform 0.3s",

                }}
              >
                {innerdetails.imageUrl.length !== 0 &&
                  innerdetails.imageUrl.map((item, index) => (
                    <div
                      key={index}
                      className='h-[80px] w-[90px] rounded-[7px] cursor-pointer'

                      onClick={() => {
                        setIsVideo(false);
                        setSelectedImage(item);
                        setIsCoverPhotoSelected(false)
                        setIsSelected(index);
                      }}
                    >
                      <img
                        src={item}
                        className={`  h-[80px] w-[90px]  rounded-[8px] ${isSelected === index && 'border-[2px] border-[#F37521]'} `}

                      />
                    </div>
                  ))}
                {innerdetails?.videoUrl !== "" && (

                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1GgiulqxH12vBGPxV7GxRQEsRtj2B1w2yew&s'
                    className={`h-[90px] w-[90px]  rounded-[8px] ${isVideo && 'border-[2px] border-[#F17522]'}`}
                    onClick={() => {
                      if (!isVideo) {
                        setIsVideo(true);
                      }
                    }}
                  />
                )}
              </div>
            </div>

            {isFullScreenImage && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 99
                }}
                onClick={() => setIsFullScreenImage(false)}
              >
                <img
                  src={selectedImage}
                  alt={`Full Screen Image ${selectedImage}`}
                  style={{
                    maxHeight: "90vh",
                    maxWidth: "90vw",
                    zIndex: 99
                  }}
                  onClick={() => setIsFullScreenImage(false)}
                />
              </div>
            )}

            <div className="w-full lg:mb-0 md:mb-0 mb-3 flex justify-center mt-2">
              <div
                style={{ display: "flex", cursor: "pointer" }}
                className="gap-2"
              >
                <ArrowBackIosNewIcon
                  onClick={() => prevButtonClickHandler()}
                  style={{
                    backgroundColor: "#fff",
                    padding: 5,
                    borderRadius: 50,
                  }}
                />
                <ArrowForwardIosIcon
                  onClick={() => nextButtonClickHandler()}
                  style={{
                    backgroundColor: "#fff",
                    padding: 5,
                    borderRadius: 50,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 md:mt-0 sm:mt-5 col-md-6 col-lg-4"

          >


            {/* contact information */}
            <div className="md:ml-3 lg:ml-3 ml-0">
              <div
                className="bg-white  px-2  shadow-md pt-3"
                style={{ borderRadius: "10px" }}
              >
                <div>
                  <div>
                    <div className=" font-semibold text-[12px]  h-[80px]">
                      <p className="uppercase">{strings.contactInfo}</p>
                      <div className="flex w-full justify-between">
                        <p className="text-[10px]"> {
                          innerdetails?.basicInformation?.contactInformation
                            ?.nameSurname
                        }</p>

                        <Avatar
                          src={innerdetails?.user?.image}
                          style={{
                            height: 80,
                            width: 80,
                          }}
                          className="-mt-4 rounded-full items-end mr-5"
                        />
                      </div>

                    </div>



                  </div>

                </div>
                <br />

                <div className="text-black text-[12px]">{innerdetails?.user?.phone}</div>
                <br />
                <div
                  className="d-flex justify-center "

                >
                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("whatsapp")
                    && (
                      <img
                        src={ws}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => sendWtpMessage(innerdetails?.user?.phone)}
                      />
                    )}

                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("sms") && (
                      <img
                        src={msg}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => sendSMS(innerdetails?.user?.phone)}
                      />
                    )}
                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("call") && (
                      <img
                        src={phones}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => phoneCall(innerdetails.user.phone)}
                      />
                    )}
                  {innerdetails?.basicInformation?.contactInformation?.contactList
                    .map(contact => contact.toLowerCase())
                    .includes("telegram") && (
                      <img
                        src={send}
                        alt=""
                        className='cursor-pointer h-[80px]'
                        onClick={() => navigateToTelegram(innerdetails?.user?.name)}
                      />
                    )}
                </div>
              </div>
              {!(
                innerdetails.user &&
                (innerdetails.user.phone === userPhoneNumber ||
                  innerdetails.user.email === userEmail)
              ) && (

                  <div
                    className=" w-full text-xs bg-[#F17522] cursor-pointer p-[10px] text-center rounded-lg text-white uppercase mt-2 "
                    onClick={() => {
                      if (innerdetails.user === null) {
                        toast.error("Ad User not found");
                      } else {
                        if (Cookies.get("userDetails") === null || Cookies.get("userDetails") === undefined) {
                          toast.warn(`${strings.pleaseSignIn}`)
                        } else {
                          navigate("/reportAd", {
                            state: {
                              innerdetails: {
                                adds: innerdetails._id,
                                name: innerdetails.user.name,
                                email: innerdetails.user.email,
                                imageUrl: "",
                                title: innerdetails.basicInformation.title,
                                reportType: "",
                                detail: "",
                              },
                            },
                          });
                        }

                      }
                    }}
                  >
                    {strings.reportad}
                  </div>
                )}
              <img
                className="mt-3 w-full hidden md:block lg:block shadow-md"
                src={MaskGroup}
                alt=""
                style={{ height: "400px", borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/*...............Details Sect...................*/}
      <div className="container ">
        <div className="row mt-3 ">
          <div className="col-12 col-md-6 col-lg-8 ">
            <div className="box ">
              <div
                className="row bg-white rounded-md"
                style={{
                  boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                }}
              >
                <div className="col-12 col-md-6 col-lg-9 d-flex align-middle ">
                  <div className="flex p-1">
                    <button
                      className="py-1 px-4 rounded-md justify-center flex flex-col text-center md:h-12 lg:h-12  flecol"
                      onClick={() => {
                        handleTabClick("tab1");
                      }}
                      style={{
                        backgroundColor:
                          activeTab === "tab1" ? "#f37521" : "transparent",
                        color: activeTab === "tab1" ? "#fff" : "#000",
                      }}
                    >
                      <EventNoteOutlinedIcon
                        style={{
                          display: window.innerWidth > 768 ? "none" : "block",
                          marginLeft: "14px",
                        }}
                      />
                      <div>{strings.moreDetails}</div>
                    </button>

                    <button
                      style={{
                        backgroundColor:
                          activeTab === "tab2" ? "#f37521" : "transparent",
                        color: activeTab === "tab2" ? "#fff" : "#000",
                      }}
                      className="py-1 px-4 rounded-md md:h-12 lg:-12 flexol "
                      onClick={() => {
                        handleTabClick("tab2");
                      }}
                    >
                      <FormatListNumberedOutlinedIcon
                        style={{
                          display: window.innerWidth > 768 ? "none" : "block",
                          marginLeft: "14px",
                        }}
                      />
                      <div>{strings.features}</div>
                    </button>
                    <button
                      style={{
                        backgroundColor:
                          activeTab === "tab3" ? "#f37521" : "transparent",
                        color: activeTab === "tab3" ? "#fff" : "#000",
                      }}
                      className="py-1 px-4 rounded-md md:h-12 lg:md-12 fx-col  "
                      onClick={() => handleTabClick("tab3")}
                    >
                      <PlaceOutlinedIcon
                        style={{
                          display: window.innerWidth > 768 ? "none" : "block",
                          marginLeft: "14px",
                        }}
                      />
                      <div> {strings.location}</div>
                    </button>
                  </div>
                </div>
              </div>

              {activeTab === "tab1" && (
                <div className="row ">
                  <div className=" flex mt-3 w-full px-0 justify-between ">
                    <div className="border w-[48%] bg-gray-200 shadow-md rounded-lg mt-2 mb-2 overflow-hidden">
                      <div
                        className={`flex justify-between p-2 px-3  bg-white
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <img
                            className="w-5 h-5 "
                            src={idIcon}
                            alt=""
                          />
                          <h1 className="text-[12px] mt-1 ">
                            ID
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px] capitalize mt-1">
                            {innerdetails.customId}
                          </h1>
                        </div>
                      </div>

                      <div
                        className={`flex justify-between p-2 px-3
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <img
                            className="w-5 h-5 "
                            src={calendar}
                            alt=""
                          />
                          <h1 className="text-[12px] mt-1 ">
                            {capitalizeFirstLetter(strings.listingDate)}
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px] capitalize mt-1">
                            {moment(innerdetails.createdAt).format("DD/MM/YYYY")}
                          </h1>
                        </div>
                      </div>

                      <div
                        className={`flex justify-between p-2 px-3  bg-white
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <CropSquareIcon className="w-5 h-5 m" />
                          <h1 className="text-[12px] mt-1 ">
                            {strings.net} {strings.area}
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px]  mt-1">
                            {innerdetails?.basicInformation?.area?.net} m<sup>2</sup>
                          </h1>
                        </div>
                      </div>


                      <div
                        className={`flex justify-between p-2 px-3 
                            }`}
                      >
                        <div className="flex ite gap-[10px]">
                          <CropSquareIcon className="w-5 h-5 m" />
                          <h1 className="text-[12px] mt-1 ">
                            {strings.gross} {strings.area}
                          </h1>
                        </div>
                        <div>
                          <h1 className=" text-[12px]  mt-1">
                            {innerdetails?.basicInformation?.area?.gross} m<sup>2</sup>
                          </h1>
                        </div>
                      </div>
                      {innerdetails?.basicInformation?.details?.slice(0, ((innerdetails?.basicInformation?.details?.length / 2) - 2)).map((item, index) => (
                        <div
                          className={`flex justify-between p-2 px-3  ${index % 2 === 0 ? "bg-white" : ""
                            }`}
                        >
                          <div className="flex ite gap-[10px]">
                            <img
                              className="w-5 h-5 "
                              src={item.imageUrl}
                              alt=""
                            />
                            <h1 className="text-[12px]   mt-1 ">
                              {
                                capitalizeFirstLetter((item.fieldName === "Number Of Rooms" && innerdetails.basicInformation.category._id === "63e648a48ce10aea9e9062e9") ? "Bedrooms" : item.fieldName)
                              }

                            </h1>
                          </div>
                          <div>
                            <h1 className=" text-[12px]  capitalize mt-1">
                              {
                                capitalizeFirstLetter(item.value)
                              }

                            </h1>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="border  w-[48%] bg-gray-200 shadow-md rounded-lg mt-2 mb-2 overflow-hidden">
                      {updatedDetails?.slice(((innerdetails?.basicInformation?.details?.length / 2) - 2), innerdetails?.basicInformation?.details?.length).map((item, index) => (
                        <div
                          className={`flex justify-between p-2 px-3  item ${index % 2 === 0 ? "bg-white" : ""
                            }`}
                        >
                          <div className="flex ite gap-[10px]">
                            <img
                              className="w-5 h-5 "
                              src={item.imageUrl}
                              alt=""
                            />
                            <h1 className="text-[12px] mt-1 ">
                              {
                                capitalizeFirstLetter(item.fieldName)
                              }

                            </h1>
                          </div>

                          <div>

                            <h1 className=" text-[12px] capitalize mt-1">
                              {item.value === "true"
                                ? "Yes"
                                : item.value === "false"
                                  ? "No"
                                  : item.value && capitalizeFirstLetter(item.value)}
                            </h1>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>


                  <div className="mt-3 w-full px-0  ">
                    <h2
                      className="text-lg font-semibold ml-2  mt-2"
                      style={{ textAlgin: "start" }}
                    >
                      {strings.desc}
                    </h2>

                    <p className="text-xs ml-2">
                      {innerdetails?.basicInformation?.description}
                    </p>
                  </div>


                </div>
              )}

              {activeTab === "tab2" && (
                <>
                  <div>
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.extFeatures}
                    </h4>

                    <div className="grid grid-cols-3 gap-1  md:grid-cols-3 lg:grid-cols-7 ">
                      {innerdetails?.externalFeatures?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((item) => (
                        <div className="bg-white gap-1 rounded-lg w-24  text-center justify-center flex flex-col shadow-md p-1  items-center  feature-st">
                          <img
                            className="w-  h-8 mt-1"
                            src={item.imageUrl}
                            alt=""
                          />

                          <p
                            className="text-center mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {item.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.internalFeatures}
                    </h4>

                    <div className="grid grid-cols-3  gap-1 md:grid-cols-4 lg:grid-cols-7 ">
                      {innerdetails?.internalFeatures?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((internal) => (
                        <div className="bg-white gap-1 rounded-lg w-24  text-center justify-center flex flex-col shadow-md p-1  items-center ure-styles">
                          <img
                            className="w- mt-1 h-8 w-8"
                            src={internal.imageUrl}
                            alt=""
                          />
                          <p
                            className="text-center mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {internal.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.neigh}
                    </h4>

                    <div className="grid grid-cols-3  gap-1 md:grid-cols-4 lg:grid-cols-7 ">
                      {innerdetails?.neighborhood?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((internal) => (
                        <div className="bg-white gap-1 rounded-lg w-24  text-center justify-center flex flex-col shadow-md p-1  items-center ure-styles">
                          <img
                            className="w- mt-1 h-8 w-8"
                            src={internal.imageUrl}
                            alt=""
                          />
                          <p
                            className="text-center mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {internal.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="">
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.accessToTr}
                    </h4>

                    <div className="grid grid-cols-3  gap-1 md:grid-cols-4 lg:grid-cols-7 ">
                      {innerdetails?.accessToTransportation?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((internal) => (
                        <div className="bg-white gap-1 rounded-lg w-24  text-center justify-center flex flex-col shadow-md p-1  items-center ure-styles">
                          <img
                            className="w- mt-1 h-8 w-8"
                            src={internal.imageUrl}
                            alt=""
                          />
                          <p
                            className="text-center mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {internal.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <h4 className="mt-4 font-semibold text-base">
                      {strings.disabledFacilities}
                    </h4>

                    <div className="grid grid-cols-3  gap-1 md:grid-cols-4 lg:grid-cols-7 ">
                      {innerdetails?.disabledFacilities?.sort((a, b) => a.fieldName.localeCompare(b.fieldName)).map((internal) => (
                        <div className="bg-white gap-1 rounded-lg w-24  text-center justify-center flex flex-col shadow-md p-1  items-center ure-styles">
                          <img
                            className="w- mt-1 h-8 w-8"
                            src={internal.imageUrl}
                            alt=""
                          />
                          <p
                            className="text-center mb-1"
                            style={{ fontSize: "10px" }}
                          >
                            {internal.fieldName}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                </>
              )}
              {activeTab === "tab3" && (
                <>
                  <h1 className="font-semibold text-lg mt-4">
                    {strings.location}
                  </h1>
                  <div className="flex justify gap-5 text-sm     h-6">
                    <p className="w-28">{strings.country}</p>
                    <p>{innerdetails.location.country}</p>
                  </div>

                  <div className="flex gap-5 text-sm justify  h-6">
                    <p className="w-28">{strings.city}</p>
                    <p>{innerdetails.location.city}</p>
                  </div>

                  <div className="flex text-sm gap-5 justify-content- mb-2     h-6">
                    <p className="w-28">{strings.distt}</p>
                    <p>{innerdetails.location.district}</p>
                  </div>

                  <div
                    className="flex justify-center "
                    style={{ height: "100px" }}
                  >
                    <Map geopoints={geopoints} />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4  hide-scrollbar` ">
            <h6
              className="font-bold text-center uppercase -mt-1"
            >
              {strings.similarAds}
            </h6>
            <div className="h-[1060px] w-[103%] overflow-y-auto overflow-x-visible hide-scrollbar">
              {recommendedDataLoading ? (
                <div className="container flex justify-center">
                  <div>
                    <ClipLoader
                      loading={recommendedDataLoading}
                      size={50}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </div>
              ) : (
                recommendedData.slice(0, 8).map((item, index) => (
                  <div
                    className="rounded-lg  shadow-md bg-white flex myc mt-[10px] border border-gray-50 "
                    key={index}

                    style={{
                      borderRadius: 12,
                      height: 140,
                    }}
                  >
                    <div
                      onClick={() => {
                        handleRealEstateClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.location.city, item.location.district)

                        window.location.reload();
                      }}
                      className="r round w-1/2 cursor-pointer"
                    >
                      <img
                        src={item.imageUrl[0]}
                        style={{
                          // borderTopLeftRadius: "24px",
                          // borderBottomLeftRadius: "24px",
                          height: 140,
                          width: '100%',
                          display: "block",
                        }}
                        className="rounded-tl-lg rounded-bl-lg"
                      />
                    </div>
                    <div
                      className=" p-2 pt-3 w-1/2  overf"
                      style={
                        {
                          // borderRadius: "10px",
                          // overflow: "hidden", // Hide any overflow
                        }
                      }
                    >
                      <div
                        className="d-flex justify-content-between"
                      // style={{ width: "200px" }}
                      >
                        <h6
                          className=" text-truncate text-sm font-medium"
                        // style={{ width: "100%" }}
                        >
                          {item.basicInformation.title}
                        </h6>


                        {!item?.fav?.includes(userId) ? (
                          <FavoriteBorderOutlinedIcon
                            className=" items-content-end w-12"
                            style={{
                              right: "8px",
                              color: "#F37521",
                              backgroundColor: "white",
                              borderRadius: 100,
                              cursor: "pointer",
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                addToFavoritesGeneral(event, item._id);
                              }
                            }}
                          />
                        ) : (
                          <FavoriteOutlinedIcon
                            className=" items-content-end w-12 opacity-100"
                            style={{
                              right: "8px",
                              color: "red",
                              backgroundColor: "white",
                              cursor: "pointer",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                addToFavoritesGeneral(event, item._id);
                              }
                            }}
                          />
                        )}
                      </div>

                      <p
                        style={{
                          fontSize: "10px",
                          // paddingTop: "10px",
                          opacity: "0.3",
                          // width: "200px",
                          whiteSpace: "nowrap", // Prevent text from wrapping
                          overflow: "hidden", // Hide any overflow
                          textOverflow: "ellipsis", // Add ellipsis (...) for truncated text
                        }}
                      >
                        {item.basicInformation.description
                          .split(" ")
                          .slice(0, 5)
                          .join(" ")}
                      </p>

                      <h5
                        style={{
                          color: "#F37521",
                          // width: "200px",
                          fontSize: "14px",
                          fontWeight: "700",
                        }}
                      >
                        {item.basicInformation.price.currency}{" "}
                        {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </h5>


                    </div>


                  </div>
                ))
              )}
            </div>
          </div>

        </div>
      </div>
      <Footer strings={strings} />
    </>
  );
};

export default Body;
