import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AdInformation from "../../images/AdInformation.svg";

import Revieworange from "../../images/Revieworange.svg";
import review from "../../images/Review.svg";

import Cookies from "js-cookie";
import Edit from "../../images/Edit.svg";
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";

import strings from "../../utils/locals/languages";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import apiClient from "../../utils/apiClient";
import { ClipLoader } from "react-spinners";

const RealEstatePreview = ({ isEdit }) => {
  const [updatedData, setUpdatedData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0)
  const button1Ref = React.useRef(null);
  const button2Ref = React.useRef(null);
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const vehicleData = location.state.formik;
  const basicInfo = location.state.basicInfo;
  const isEditOrCreate = location.state.isEditOrCreate;
  const [menu1Open, setMenu1Open] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };

  const handleMenu1Close = () => {
    setMenu1Open(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const manageAd = Cookies.get("manageAd")

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    Cookies.remove("manageAd")
    window.location.reload();
  };

  const callApiAndGoNext = async () => {
    try {
      const response = await apiClient.get('/v1/user/check-user')
      setIsLoading(false)
      if (response.data.success === true) {
        if (isEdit) {
          await apiClient.put(`/v1/adds/update-adds/${vehicleData._id}`, vehicleData);
          toast.success(`${strings.adUpdateMsg}`);
          navigate('/')
        } else {
          navigate('/select-package', {
            state: {
              isEditOrCreate,
              vehicleData
            }
          })
        }

      } else {
        setIsLoading(false)
        toast.error(response.data.msg)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error.response.data.msg)
    }
  }
  const goNext = async () => {

    if (!checked) {
      toast.error(`${strings.acceptRules}`)
    } else {
      setIsLoading(true)
      await callApiAndGoNext()
    }



    sessionStorage.setItem('vehicleData', JSON.stringify(vehicleData));

  }

  return (
    <>

      <div className="container">


        <div className="flex justify-center mt-4">
          <div>
            <img className="w-16 ms-4" src={AdInformation} alt="" />
            <p
              style={{ color: "#F37521", fontSize: "14px", fontWeight: "bold" }}
            >
              {strings.adInfo}
            </p>
          </div>

          <div
            className="mt-4 "
            style={{
              width: "14%",

              background: "#F37521",
              height: "1px",
            }}
          >
            <p></p>
          </div>
          <div>
            <img className="w-16" src={Revieworange} alt="" />
            <p
              style={{ color: "#F37521", fontSize: "14px", fontWeight: "bold" }}
            >
              {strings.reveiews}
            </p>
          </div>
          <div
            className="mt-4 "
            style={{
              width: "14%",
              background: "#F37521",
              height: "1px",
            }}
          >
            <p></p>
          </div>
          <div>
            <img className="w-16" src={review} alt="" />
            <p
              style={{ color: "#F37521", fontSize: "14px", fontWeight: "bold" }}
            >
              {strings.pkg}
            </p>
          </div>
        </div>
      </div>

      {/*Section 3*/}
      <div className="container mx-auto px-4 mt-5">
        <div className="bg-white rounded-lg">
          <div className="flex justify-content-between pt-5 p-4">
            <h4>{strings.images}</h4>
            <img
              className="w-8 cursor-pointer"
              src={Edit}
              alt=""
              onClick={() => {
                navigate("/edit-realestate", {
                  state: {
                    realEstateData: vehicleData,
                    newAdd: true,
                    basicInfo: basicInfo
                  }
                });
              }}
            />
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 m-3">
            {vehicleData.imageUrl?.length === 0
              ? "No Images"
              : Array.from(new Set(vehicleData?.imageUrl?.map(file => file))).map((image, index) => (
                <img style={{ height: 120, width: 150 }} src={`${image}`} alt="" key={index} />
              ))}
          </div>

          <div className="flex justify-content-between m-3 px-2">
            <h4>{strings.video}</h4>
          </div>

          <div className="flex justify-content-between m-3 px-2">
            {
              vehicleData?.videoUrl && <video controls className="h-80 w-200">
                <source
                  src={vehicleData?.videoUrl}
                />
              </video>
            }

          </div>


          <div className="flex justify-content-between m-3 px-2">
            <h4>{strings.basicInfo}</h4>
          </div>

          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.purpose}</p>
            <p>{vehicleData.purpose}</p>
          </div>

          <div className="flex justify-content-between m-2 px-2 mb-3  h-6">
            <p>{strings.title}</p>
            <p>{vehicleData.basicInformation.title}</p>
          </div>

          <div className="flex flex-wrap justify-content-between m-3 px-2 bg-gray-300">
            <p>{strings.desc}</p>
            <p>{vehicleData.basicInformation.description}</p>
          </div>
          <div className="flex flex-wrap justify-content-between m-3 px-2 bg-gray-300">
            <p>{strings.category}</p>
            <p>{basicInfo.category}</p>
          </div>
          <div className="flex flex-wrap justify-content-between m-3 px-2 bg-gray-300">
            <p>{strings.brand}</p>
            <p>{basicInfo.brand}</p>
          </div>

          <div className="flex justify-content-between m-3 px-2 h-6">
            <p>{strings.area}</p>
            <p>{vehicleData.basicInformation.area.gross}</p>
          </div>

          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.price}</p>
            <p>{vehicleData.basicInformation.price.price}</p>
          </div>

          <div className="container">
            <h4>{strings.details}</h4>

            {vehicleData.basicInformation.details.map((detail, index) => (
              <div
                className={`flex justify-between p-1 items-center ${index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
              >
                <p>{detail?.fieldName}</p>
                <p>{detail?.value}</p>
              </div>
            ))}
          </div>

          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.purpose}</p>
            <p>{vehicleData.purpose}</p>
          </div>

          <div className="flex justify-content-between m-2 px-2 mb-3 mt-5  h-6">
            <h5>{strings.location}</h5>
          </div>

          <div className="flex justify-content-between m-3    h-6">
            <p>{strings.country}</p>
            <p>{vehicleData.location.country}</p>
          </div>

          <div className="flex justify-content-between m-2 px-2 mb-3 bg-gray-300 h-6">
            <p>{strings.city}</p>
            <p>{vehicleData.location.city}</p>
          </div>

          <div className="flex justify-content-between m-3    h-6">
            <p>{strings.distt}</p>
            <p>{vehicleData.location.district}</p>
          </div>

          <div className="flex justify-content-between m-2 px-2 mb-3 bg-gray-300 h-6">
            <p>{strings.neigh}</p>
            <p>{vehicleData.location.neighborhood}</p>
          </div>

          <div className="flex justify-content-between m-3   h-6">
            <p>{strings.complex}</p>
            <p>{vehicleData.location.complex}</p>
          </div>

          <div className="flex justify-content-between m-2 px-2 mb-3 bg-gray-300  h-6">
            <p>{strings.openadd}</p>
            <p>{vehicleData.location.openAddress}</p>
          </div>

          {/*internal features*/}
          <div className="container mt-3">
            <h4 className="">{strings.internalFeatures}</h4>
          </div>

          <div className="container mx-auto mt-3">
            <div className="flex gap-3 flex-wrap">
              {vehicleData.internalFeatures.length !== 0 &&
                vehicleData.internalFeatures.map((item) => (
                  <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                    <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                    <p
                      className="ps-1 text-center "
                      style={{ fontSize: "14px" }}
                    >
                      {capitalizeFirstLetter(item.fieldName)}

                    </p>
                  </div>
                ))}
            </div>
          </div>

          {/*>External features*/}
          <div className="">
            <h4 className="px-3">{strings.extFeatures}</h4>
          </div>

          <div className="container mx-auto mt-3">
            <div className="flex gap-3 flex-wrap">
              {vehicleData.externalFeatures.length !== 0 &&
                vehicleData.externalFeatures.map((item) => (
                  <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                    <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                    <p
                      className="ps-1 text-center "
                      style={{ fontSize: "14px" }}
                    >
                      {capitalizeFirstLetter(item.fieldName)}
                    </p>
                  </div>
                ))}
            </div>
          </div>

          {/*Saftey features*/}
          <div className="container">
            <h4 className="">{strings.accessToTr}</h4>
          </div>

          <div className="container mx-auto mt-3">
            <div className="flex gap-3 flex-wrap">
              {vehicleData.accessToTransportation.length !== 0 &&
                vehicleData.accessToTransportation.map((item) => (
                  <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                    <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                    <p
                      className="ps-1 text-center "
                      style={{ fontSize: "14px" }}
                    >
                      {capitalizeFirstLetter(item.fieldName)}
                    </p>
                  </div>
                ))}
            </div>
          </div>

          {/*Neighborhood*/}
          <div className="container">
            <h4 className="">{strings.neigh}</h4>
          </div>

          <div className="container mx-auto mt-3">
            <div className="flex gap-3 flex-wrap">
              {vehicleData.neighborhood.length !== 0 &&
                vehicleData.neighborhood.map((item) => (
                  <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                    <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                    <p
                      className="ps-1 text-center "
                      style={{ fontSize: "14px" }}
                    >
                      {capitalizeFirstLetter(item.fieldName)}
                    </p>
                  </div>
                ))}
            </div>
          </div>

          {/*Neighborhood*/}
          <div className="container">
            <h4 className="">{strings.fac}</h4>
          </div>

          <div className="container mx-auto mt-3">
            <div className="flex gap-3 flex-wrap">
              {vehicleData.disabledFacilities.length !== 0 &&
                vehicleData.disabledFacilities.map((item) => (
                  <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                    <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                    <p
                      className="ps-1 text-center "
                      style={{ fontSize: "14px" }}
                    >
                      {capitalizeFirstLetter(item.fieldName)}
                    </p>
                  </div>
                ))}
            </div>
          </div>

          <div className="px-3 pt-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              required
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <label className="form-check-label px-3" for="exampleCheck1">
              <div className="flex">
                <p>{strings.accept}</p>{" "}
                <p className="text-orange-400 ps-2 cursor-pointer"
                  onClick={() => {
                    window.open('/terms-of-use', '_blank');
                  }}
                >{strings.adPosting}</p>
              </div>
            </label>
          </div>

          <div className="flex justify-center ">
            <button className="bg-orange-400 p-3 rounded-full text-white sm:w-72 md:w-80 w-64 mb-5 mt-4"
              onClick={() => goNext()}
            >
              {
                isLoading ? <ClipLoader size={12} color="#fff" /> : (
                  isEdit ? strings.update : strings.next
                )
              }
            </button>

          </div>
        </div>
      </div>

      {/*footer*/}
      <Footer strings={strings} />
    </>
  );
};

export default RealEstatePreview;
